import { createApp } from "vue";
import { createPinia } from "pinia";
import { spiels } from "@/utils/spiels";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import { datadogRum } from "@datadog/browser-rum";
import { client } from "@/services/api.js";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.provide("spiels", spiels);

app.use(router).use(pinia);

app.config.globalProperties.$axios = client;

app.mount("#app");

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: "8e1453dd-f9a3-44f7-9346-c3c17c688243",
    clientToken: "pub2d3c0ddbbd04188ca5ba94c57895e719",
    site: "us3.datadoghq.com",
    service: "tax-admin",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: [
      "https://aaapi.pluginaccountant.com",
      "https://api.pluginaccountant.com",
    ],
  });
}
