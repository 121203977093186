import { createDiscreteApi } from "naive-ui";
import { spiels } from "@/utils/spiels";
import { datadogRum } from "@datadog/browser-rum";
import { useClientStore } from "@/stores/clientStore";

const axios = require("axios");
const { message } = createDiscreteApi(["message"]);

const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: true,
});

const customEventUrl = "/api/user/custom-event";

const sendToDatadogRUM = (response, isError = false) => {
  const url = response.config?.url;
  if (!url?.includes(customEventUrl)) {
    if (isError) {
      datadogRum.addAction(`Error on ${url}`, {
        response,
      });
    } else {
      datadogRum.addAction(url, {
        response,
      });
    }
  }
};

client.interceptors.request.use(
  (config) => {
    const store = useClientStore();
    store.startLoading();
    return config;
  },
  (error) => {
    const store = useClientStore();
    store.stopLoading();
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    const store = useClientStore();
    store.stopLoading();
    sendToDatadogRUM(response);
    return response;
  },
  (error) => {
    const store = useClientStore();
    store.stopLoading();
    const isError = true;
    sendToDatadogRUM(error, isError);

    switch (error.response?.status) {
      case 401: {
        message.error(spiels.ERROR.UNATHORIZED, {
          closable: true,
          duration: 3000,
          onLeave: () => {
            localStorage.clear();
            window.location = "/";
          },
          onClose: () => {
            localStorage.clear();
            window.location = "/";
          },
        });
        break;
      }
      case 403: {
        const isCustomEventApiCall = error.config?.url.includes(customEventUrl);
        if (isCustomEventApiCall) {
          message.error(spiels.ERROR.SESSION_EXPIRED, {
            closable: true,
            duration: 3000,
            onLeave: () => {
              localStorage.clear();
              window.location = "/";
            },
            onClose: () => {
              localStorage.clear();
              window.location = "/";
            },
          });
        }
        break;
      }
    }
    return Promise.reject(error);
  }
);

export { client };
